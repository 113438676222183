import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Intro } from '../../components/Intro/Intro';
import { StyledLink } from '../../components/StyledLink';
import Projects from '../../components/Projects';
import { Container, CSSGrid, Col } from '../../components/Grid';
import { designTokens } from '../../components/Theme/designTokens';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Work" keywords={['Ryan Parag', 'Designer', 'UI/UX', 'Tampa']} mdxType="SEO" />
    <Container mdxType="Container">
  <CSSGrid mdxType="CSSGrid">
    <Col mdxType="Col">
      <Intro initial={{
            y: `-${designTokens.space[5]}`,
            opacity: 0
          }} animate={{
            y: 0,
            opacity: 1
          }} transition={{
            duration: 0.25,
            delay: 0.1
          }} mdxType="Intro">
        <h2>Work</h2>
        <p className="lead">Below are some case studies and work samples of projects that I’ve been involved with.</p>
      </Intro>
    </Col>
  </CSSGrid>
    </Container>
    <Projects mdxType="Projects" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      